<template>
  <section class="page page-tariffs">
    <div class="wrapper">

      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Оплата платформы</span>
      </div>

      <div class="page-title">
        <h1>Оплата платформы</h1>
      </div>

      <form class="block-tariffs" @submit.prevent="">
        <div class="main">
          <div class="tariffs-header-wrapper">
            <div class="tariffs-user"></div>

            <div class="tariffs-header-information-wrapper">
              <div class="tariffs-title">
                Доступные знания по доступной цене
              </div>
              <div class="tariffs-description">
                Мы стараемся сделать нашу платформу информативной и доступной.
                Если у тебя остались какие-то вопросы можешь задать их на нашей
                <a
                  href="/#linkToContactUs"
                  class="btn btn-sm btn-info"
                  role="button"
                  >главной странице</a
                >
              </div>
            </div>
          </div>
          <div class="tariffs-blocks-wrapper">
            <div
              class="tariffs-blocks"
              v-for="tariff of tariffs"
              :key="tariff.subscriptionPosition"
            >
              <div
                v-on:click="toggleActivatedBlock(tariff.subscriptionPosition)"
                :class="{
                'tariffs-blocks-active':
                  activatedBlock === tariff.subscriptionPosition
              }"
                @mouseenter="tariff.hover = true"
                @mouseleave="tariff.hover = false"
              >
                <div>
                  <div class="tariffs-blocks-header">
                    {{ tariff.periodDescription }}
                  </div>
                  <div class="tariffs-blocks-description">
                    {{ tariff.subscriptionDescription }}
                  </div>
                  <div
                    v-show="tariff.oldPrice != null"
                    class="tariffs-blocks-old-price"
                  >
                    цена: {{ tariff.oldPrice }}₽
                  </div>
                  <div class="tariffs-blocks-new-price">
                    цена: {{ tariff.price }}₽
                  </div>

                  <button v-if="!isMobile()" class="button-small">
                    Выбрать
                  </button>

                  <!-- todo remove that shit or replace properly not using a period description field -->
                  <!--
                  <router-link
                    :to="{ path: '/junior' }"
                    v-if="tariff.periodDescription === '5 месяцев'"
                  >
                    <button class="button-small">
                      Подробнее
                    </button>
                  </router-link>
                  <router-link
                    :to="{ path: '/telegram' }"
                    v-if="tariff.periodDescription === '1 месяц'"
                  >
                    <button class="button-small">
                      Подробнее
                    </button>
                  </router-link>
                  <router-link
                    :to="{ path: '/personal' }"
                    v-if="tariff.periodDescription === '1 академический час'"
                  >
                    <button class="button-small">
                      Подробнее
                    </button>
                  </router-link>
                  -->

                </div>
              </div>
            </div>
            <div class="tariffs-blocks">
              <div
                v-for="tariff of promoTariffs"
                :key="tariff.subscriptionPosition"
                v-on:click="toggleActivatedBlock(tariff.subscriptionPosition)"
                :class="{
                'tariffs-blocks-active':
                  activatedBlock === tariff.subscriptionPosition
              }"
              >
                <div class="tariffs-blocks-header">
                  {{ tariff.periodDescription }}
                </div>
                <div
                  v-show="tariff.oldPrice != null"
                  class="tariffs-blocks-old-price"
                >
                  цена: {{ tariff.oldPrice }}₽
                </div>
                <div class="tariffs-blocks-new-price">
                  Цена: {{ tariff.price }}₽
                </div>
              </div>
            </div>
          </div>

          <div class="promo-group">
            <input
              class="promo-control"
              v-model="promo"
              placeholder="Введите промокод!"
              type="text"
            />
            <div class="button-small">
              <button class="button-small" @click="promoCode">
                Применить!
              </button>
            </div>
          </div>
          <div class="bottom">
            <button class="button payment" @click="submitHandler()">
              Оплатить!
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { NotificationService } from "@/services";

export default {
  name: "Tariffs",
  mounted() {
    axios
      .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/subscriptions/")
      .then(response => {
        this.tariffs = response.data;

        // Create a shallow copy of the tariffs array
        const sortedTariffs = [...this.tariffs];

        // Sort the copy of the array by the 'subscriptionPosition' field
        sortedTariffs.sort((a, b) => a.subscriptionPosition - b.subscriptionPosition);

        // Assign the sorted copy back to the tariffs data
        this.tariffs = sortedTariffs;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
    axios
      .get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/user-subscriptions-trial/isTrialAvailable/"
      )
      .then(response => (this.isTrialAvailable = response.data));
  },
  data() {
    return {
      promo: "",
      tariffs: {},
      promoTariffs: {},
      activatedPromo: "",
      activatedBlock: 1,
      trailBlock: 3
    };
  },
  components: {},
  methods: {
    async promoCode() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/subscriptions/promo/" +
            this.promo
        )
        .then(response => {
          this.promoTariffs = response.data;
          this.tariffs = {};
          this.activatedPromo = this.promo;
        })
        .catch(err => {
          if (err.response.status === 403) {
            NotificationService.warning(
              "Перед активацией промокода необходимо авторизоваться."
            );
            this.$router.push("/login");
          }
          if (err.response.status === 409) {
            NotificationService.warning("Вы уже использовали данный промокод");
          }
          if (err.response.status === 400) {
            NotificationService.warning(
              "Срок действия или количество активаций данного промокода истекло"
            );
          }
          if (err.response.status === 404) {
            NotificationService.warning("Промокод не найден");
          }
        });
    },
    async submitHandler() {
      const createNewPayment = await axios
        .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/payments/active")
        .then(res => {
          return confirm(
            "У вас уже есть платёж, ожидающий активации. " +
              "Вы можете посмотреть его статус в платёжной системе, перейдя по ссылке: " +
              res.data.confirmationUrl +
              ". " +
              'Если же вы хотите создать новый платёж, нажмите "ОК".'
          );
        })
        .catch(err => {
          if (err.response.status === 403) {
            NotificationService.warning(
              "Перед активацией подписки необходимо авторизоваться."
            );
            this.$router.push("/login");
          }
          if (err.response.status === 404) {
            return true;
          }
        });

      if (createNewPayment) {
        await axios
          .post(
            process.env.VUE_APP_BACKEND_ROOT_URL +
              "/user-subscriptions/activate",
            {
              subscriptionPosition: this.activatedBlock,
              promoCode: this.activatedPromo
            }
          )
          .then(() => {
            axios
              .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/payments/active")
              .then(res => {
                NotificationService.success(
                  "Сейчас вы будете перенаправлены для оплаты."
                );
                window.location.href = res.data.confirmationUrl;
              });
          })
          .catch(err => {
            if (err.response.status === 409) {
              NotificationService.warning("Подписка была активирована ранее.");
              this.$router.push("/");
            } else if (err.response.status === 403) {
              NotificationService.warning("Необходимо подтвердить почту.");
              this.$router.push("/settings");
            } else if (err.response.status === 404) {
              NotificationService.warning("Подписка не найдена");
            } else if (err.response.status === 400) {
              NotificationService.warning("Промокод не активен");
            } else {
              NotificationService.error(
                "Возникла системная ошибка. Пожалуйста, свяжитесь с администрацией."
              );
            }
          });
      }
    },
    async submitTrial() {
      await axios
        .post(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-subscriptions-trial/activate/probation-period",
          {
            subscriptionPosition: this.trailBlock
          }
        )
        .then(res => {
          if (res.status === 200) {
            NotificationService.success(
              "Подписка успешно активирована. Большое спасибо!"
            );
            this.$store
              .dispatch("FETCH_USER")
              .then(() => this.$router.push("/"));
          }
        })
        .catch(err => {
          if (err.response.status === 409) {
            NotificationService.warning("Подписка была активирована ранее.");
            this.$router.push("/");
          } else if (err.response.status === 403) {
            NotificationService.warning("Необходимо подтвердить почту.");
            this.$router.push("/settings");
          } else if (err.response.status === 404) {
            NotificationService.warning("Подписка не найдена");
          } else if (err.response.status === 400) {
            NotificationService.warning("Промокод не активен");
          } else {
            NotificationService.error(
              "Возникла системная ошибка. Пожалуйста, свяжитесь с администрацией."
            );
          }
        });
    },
    toggleActivatedBlock(elem) {
      if (elem !== this.activatedBlock) {
        this.activatedBlock = elem;
      }
    },
    isMobile() {
      return screen.width <= 760;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-tariffs.scss";
</style>
